<template>
  <div id="app" :style="{ backgroundImage: `url(${require('@/assets/original-background.jpg')})`, backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', backgroundSize: 'cover' }">
    <section class="section" style="margin-top: 50px;">
      <center>
        <div class="container">
          <div class="col-md-6" v-if="status === 'valid'">
            <!-- 原有的正常显示内容保持不变 -->
            <p style="font-size: 13pt; color: black; font-weight: bold; letter-spacing: 6px; margin-bottom: 20px;">{{ code }}</p>
            <p style="color: black; font-weight: bold; line-height: 1.2; font-size: 12pt;">
              This is 100% GENUINE PRODUCT<br />
              Thank you for purchasing our genuine product.<br /><br />
              安全码认证<br />
              此产品是100%正品。<br />
              谢谢支持！<br />
              认证: {{ scanCount }}次
            </p>
            <img src="@/assets/original-logo.png" style="margin-top: 10px; width: 40%;" />
            <img src="@/assets/company-logo.jpg" style="margin-top: 25px; width: 70%;" />
            <br />
            <img src="@/assets/dermedical-skinsciences-logo.png" style="margin-top: 30px; margin-bottom: 10px; width: 50%;" />
            <br />
            <p style="font-size: 12px; font-weight: bold; color: #BD803C; line-height: 1.2; margin-bottom: 10px;">
              Via K. Marx, 18, Noverasco di Opera,<br />
              MI, 20090, Italy. Centralino: +39 010 386 527
            </p>
          </div>
          
          <!-- 警告页面 -->
          <div class="col-md-6">
          <!-- 警告容器 - 只包含警告相关内容 -->
          <div v-if="status === 'exceeded'" style="background-color: #ed323f; border-radius: 5px; padding: 15px; margin-bottom: 0px;">
            <div style="color: white;">
              <img src="@/assets/warning-sign.png" style="margin-top: 5px;" /><br>
              <p style="font-size: 12pt; margin-top: 10px; line-height: 1.2;">
                QR CODE VALIDATION FAILURE:<br>
                This QR code has exceeded the maximum allowable scans of 2. 
                Kindly reach out to our authorized distributors for further assistance.
              </p>
              <p style="font-size: 12pt; line-height: 1.2;">
                二维码认证失败<br>
                此二维码已超过最多允许的两次扫描次数。请联系我们的授权经销商以获取进一步的协助。
              </p>
            </div>
          </div>

          <!-- Logo部分 - 在红色背景外 -->
          <img src="@/assets/company-logo.jpg" style="margin-top: 25px; width: 70%;" />
          <br />
          <img src="@/assets/dermedical-skinsciences-logo.png" style="margin-top: 30px; margin-bottom: 10px; width: 50%;" />
          <br />
          <p style="font-size: 12px; font-weight: bold; color: #BD803C; line-height: 1.2; margin-bottom: 10px;">
            Via K. Marx, 18, Noverasco di Opera,<br style="line-height: 22px;" />
            MI, 20090, Italy. Centralino: +39 010 386 527
          </p>
        </div>

          <!-- 无效码页面 -->
          <div class="col-md-6" v-if="status === 'invalid'" style="background-color: #dc3545; color: white; padding: 30px; border-radius: 10px;">
            <div class="warning-symbol">
              <span>!</span>
            </div>
            <h2 style="color: #ffc107;">INVALID CODE</h2>
            <p style="color: white; font-size: 16px; margin: 20px 0;">
              This code is not recognized in our system.
              <br />
              Please verify your code or contact our authorized distributors.
            </p>
            <h3 style="color: #ffc107;">无效验证码</h3>
            <p style="color: white; font-size: 16px; margin-bottom: 30px;">
              此验证码未在我们的系统中注册。
              <br />
              请确认您的验证码或联系我们的授权经销商。
            </p>
            <img src="@/assets/company-logo.jpg" style="width: 70%; margin-bottom: 20px;" />
            <br />
            <img src="@/assets/dermedical-skinsciences-logo.png" style="width: 50%;" />
            <br />
            <p style="font-size: 12px; color: #f8f9fa; margin-top: 20px;">
              Via K. Marx, 18, Noverasco di Opera,<br />
              MI, 20090, Italy. Centralino: +39 010 386 527
            </p>
          </div>
        </div>
      </center>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: '',
      scanCount: 0,
      status: '', // 状态：valid, invalid, exceeded
      errorMessage: '',
    };
  },
  async created() {
    const code = window.location.pathname.substring(1);
    
    try {
      const response = await fetch(`/verify/${code}`);
      const data = await response.json();
      
      this.code = data.code;
      this.status = data.status;
      
      if (this.status === 'valid') {
        this.scanCount = data.message === 'First visit' ? 1 : 2;
      } else {
        this.errorMessage = data.message;
      }
    } catch (error) {
      console.error('Error:', error);
      this.errorMessage = 'An error occurred while verifying the code.';
      this.status = 'invalid';
    }
  }
};
</script>

<style>
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.section {
  margin-top: 50px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

p {
  margin: 0;
  padding: 0;
}

/* 警告符号样式 */
.warning-symbol {
  width: 60px;
  height: 60px;
  background-color: #ffc107;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px auto;
}

.warning-symbol span {
  color: #dc3545;
  font-size: 40px;
  font-weight: bold;
}

/* 添加渐变动画效果 */
.col-md-6 {
  transition: all 0.3s ease-in-out;
}

/* 警告页面特殊样式 */
.warning-box {
  box-shadow: 0 0 15px rgba(220, 53, 69, 0.5);
}
</style>